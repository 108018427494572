import type { Group } from '@pixi/layers';
import _ from 'lodash';
import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../../config';
import { EventTypes, GameMode } from '../../../global.d';
import { setBetResult, setCurrentIsTurboSpin, setSlotConfig } from '../../../gql/cache';
import { Logic } from '../../../logic';
import { getBetResult, getSpinResult, normalizePosition } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import { TweenProperties } from '../../animations/d';
import { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import {
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  eventManager,
  REEL_WIDTH,
  REELS_AMOUNT,
  ReelState,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
} from '../../config';
import Slot from '../slot';
import type { Reel } from './reel';

export class BaseReel extends ViewContainer implements Reel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public slots: Slot[] = [];

  public size: number;

  public isPlaySoundOnStop: boolean;

  public animation: ReelAnimation | null = null;

  public stopPosition: number;

  private isForceStopped = false;

  constructor(id: number, data: SlotId[], startPosition: number, slotGroup: Group) {
    super();
    this.id = id;
    this.data = data;
    this.size = this.data.length;
    this.state = ReelState.IDLE;
    this.isPlaySoundOnStop = true;
    this.sortableChildren = true;
    this.stopPosition = startPosition;
    this.width = SLOT_WIDTH;
    this.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.y = -SLOT_HEIGHT;
    this.createSlots(slotGroup);
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.isForceStopped = true;
    });
  }

  public changeReelData(data: SlotId[], slotGroup: Group, position: number): void {
    this.stopPosition = position;
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
  }

  public changeData(data: SlotId[], slotGroup: Group): void {
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
    this.toggleBlurSlots(true);
    console.log('changeData');
  }

  public createSlots(slotGroup: Group): void {
    for (let i = 0; i < this.data.length; i++) {
      const slot = new Slot(i, this.data[i as number] as SlotId, this.id);
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      slot.y = this.getSlotY(slot);

      this.addChild(slot);
    }
  }

  public getSlotY(slot: Slot): number {
    let position = -this.stopPosition;
    while (position < 0) {
      position += this.data.length;
    }
    return SLOT_HEIGHT * (((position + slot.id + 2) % this.data.length) + 0.5);
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.visible = true;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(false);
  }

  private onReelStop(): void {
    const spinResult = getSpinResult({
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
      reelSet: getBetResult(setBetResult()).bet.reelSet,
      icons: setSlotConfig().icons,
    });
    const reelResult = [spinResult[this.id], spinResult[this.id + 5], spinResult[this.id + 5 * 2]];
    const isScatterOnReelMap = [];

    for (let i = 0; i < REELS_AMOUNT; i++) {
      isScatterOnReelMap.push(
        [spinResult[i as number], spinResult[i + 5], spinResult[i + 5 * 2]].some((value) => value?.id === SlotId.SC1),
      );
    }

    this.isForceStopped = false;

    if (reelResult.some((value) => value?.id === SlotId.SC1)) {
      // Scatter stop sound logic
      if (this.id === 0) {
        AudioApi.play({ type: ISongs.ScatterAppear_1, stopPrev: true });
      }

      if (this.id === 2 && isScatterOnReelMap[0]) {
        AudioApi.play({ type: ISongs.ScatterAppear_2, stopPrev: true });
      } else {
        AudioApi.play({ type: ISongs.UI_SpinStop, stopPrev: true });
      }

      if (this.id === 4 && isScatterOnReelMap[0] && isScatterOnReelMap[2]) {
        AudioApi.play({ type: ISongs.ScatterAppear_3, stopPrev: true });
      } else {
        AudioApi.play({ type: ISongs.UI_SpinStop, stopPrev: true });
      }
    } else {
      AudioApi.play({ type: ISongs.UI_SpinStop, stopPrev: true });
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(true);
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }

  public createSpinAnimation(): ReelAnimation {
    const onChange = () => {
      this.slots.forEach((slot) => {
        slot.y = this.getSlotY(slot);
      });
    };
    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;

    const disappearingAnimation = new AnimationChain();
    disappearingAnimation.appendAnimation(Tween.createDelayAnimation(100 * this.id));
    const disappearingTarget = normalizePosition(this.size, this.stopPosition - SLOTS_PER_REEL_AMOUNT);
    const disappearingBegin = this.stopPosition;
    disappearingAnimation.appendAnimation(
      new Tween({
        object: this,
        property: TweenProperties.STOP_POSITION,
        propertyBeginValue: disappearingBegin,
        target: disappearingBegin - SLOTS_PER_REEL_AMOUNT,
        duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
        delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
        easing: BASE_REEL_STARTING_FORMULA,
      }),
    );
    disappearingAnimation.addOnStart(() => {
      this.changeState(ReelState.DISAPPEARING);
    });
    disappearingAnimation.addOnChange(onChange);
    const speed = 25;
    const duration = 12000;
    const waitingTarget = disappearingTarget - (speed * duration) / 1000;
    const waitingAnimation = new Tween({
      object: this,
      property: TweenProperties.STOP_POSITION,
      propertyBeginValue: disappearingTarget,
      target: waitingTarget,
      duration,
    });
    waitingAnimation.addOnStart(() => {
      this.changeState(ReelState.WAITING);
    });
    waitingAnimation.addOnChange(onChange);
    this.animation = new BaseAnimation({
      disappearingAnimation,
      waitingAnimation,
    });
    return this.animation;
  }

  //@TODO GARBAGE
  private toggleBlurSlots(enabled: boolean): void {
    this.slots.forEach((slot) => {
      if (slot.slotId !== SlotId.SC1) slot.toggleBlur(enabled);
    });
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.slot.tint = 0xffffff;
    });
    this.isForceStopped = false;
  }
}
