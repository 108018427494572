import _ from 'lodash';
import type { TAudioSprite } from '@money.energy/audio-api/dist/d';

// audiosprite -e 'mp4,ogg' -f 'howler' -c 2  AutoSpin.ogg BetChange.ogg ButtonClose.ogg ButtonHover.ogg ButtonPress.ogg CountUpLoop.ogg CountUpStop.ogg FeatureTrigger.ogg MainGameTheme.ogg MenuOpen.ogg SpinStart.ogg SpinStop.ogg Ambience.ogg BigWin.ogg BigWinTheme.ogg HighWin.ogg MediumWin.ogg MegaWin.ogg SmallWin.ogg SuperWin.ogg UltraWin.ogg --loop MainGameTheme --loop Ambience -o ./sprite

export enum ISongs {
  Background = 'MainGameTheme',
  WinPopup = '013_07_WinPopup',
  BigWin_Loop = '013_08_BigWin_Loop',
  BigWin_End = '013_09_BigWin_End',
  BigWin = 'BigWin',
  MegaWin = 'MegaWin',
  SmallWin = 'SmallWin',
  MediumWin = 'MediumWin',
  HighWin = 'HighWin',
  CountUp_Stop = 'CountUpStop',
  CountUp_Loop = 'CountUpLoop',
  UI_AutoSpin = 'AutoSpin',
  UI_BetChange = 'BetChange',
  UI_ButtonClose = 'ButtonClose',
  UI_ButtonPress = 'ButtonPress',
  UI_ButtonHover = 'ButtonHover',
  UI_MenuOpen = 'MenuOpen',
  UI_SpinStart = 'SpinStart',
  UI_SpinStop = 'SpinStop',
  CasinoAmbiance = 'Ambience',
  FeatureTrigger = 'FeatureTrigger',
  ScatterAppear_1 = 'ScatterAppear_1',
  ScatterAppear_2 = 'ScatterAppear_2',
  ScatterAppear_3 = 'ScatterAppear_3',
  ScatterWin = 'ScatterWin',
  UltraWin = 'UltraWin',
  SuperWin = 'SuperWin',
}

export const audioSprite: TAudioSprite = {
  [ISongs.Background]: [26000, 86399.97732426303, true],
  [ISongs.BigWin]: [161000, 4200.02267573696],
  [ISongs.BigWin_End]: [156000, 7397.00680272108],
  [ISongs.BigWin_Loop]: [167000, 46000.022675736975, true],
  [ISongs.CasinoAmbiance]: [120000, 39000.022675736975, true],
  [ISongs.CountUp_Loop]: [10000, 450.68027210884406, true],
  [ISongs.CountUp_Stop]: [12000, 2622.9024943310646],
  [ISongs.FeatureTrigger]: [16000, 8982.494331065758],
  [ISongs.HighWin]: [215000, 4800.022675736955],
  [ISongs.MediumWin]: [221000, 4800.022675736955],
  [ISongs.MegaWin]: [227000, 4800.022675736955],
  [ISongs.ScatterAppear_1]: [268000, 2468.5714285714084],
  [ISongs.ScatterAppear_2]: [272000, 2625.3061224489898],
  [ISongs.ScatterAppear_3]: [276000, 2544.03628117916],
  [ISongs.ScatterWin]: [280000, 4807.981859410404],
  [ISongs.SmallWin]: [233000, 4800.022675736955],
  [ISongs.SuperWin]: [239000, 4650.022675736949],
  [ISongs.UI_AutoSpin]: [0, 230.86167800453515],
  [ISongs.UI_BetChange]: [2000, 100.61224489795917],
  [ISongs.UI_ButtonClose]: [4000, 194.8299319727891],
  [ISongs.UI_ButtonHover]: [6000, 91.88208616780003],
  [ISongs.UI_ButtonPress]: [8000, 80.45351473922935],
  [ISongs.UI_MenuOpen]: [114000, 123.12925170067501],
  [ISongs.UI_SpinStart]: [116000, 169.43310657596555],
  [ISongs.UI_SpinStop]: [118000, 73.35600907029516],
  [ISongs.UltraWin]: [245000, 5100.0226757369655],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Background]: 0.4,
  [ISongs.WinPopup]: 0.5,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.BigWin]: 0.4,
  [ISongs.MegaWin]: 0.4,
  [ISongs.SmallWin]: 0.25,
  [ISongs.MediumWin]: 0.25,
  [ISongs.HighWin]: 0.25,
  [ISongs.CountUp_Stop]: 0.3,
  [ISongs.CountUp_Loop]: 0.6,
  [ISongs.UI_AutoSpin]: 0.4,
  [ISongs.UI_BetChange]: 0.4,
  [ISongs.UI_ButtonClose]: 0.4,
  [ISongs.UI_ButtonPress]: 0.4,
  [ISongs.UI_ButtonHover]: 0.3,
  [ISongs.UI_MenuOpen]: 0.4,
  [ISongs.UI_SpinStart]: 0.2,
  [ISongs.UI_SpinStop]: 0.2,
  [ISongs.CasinoAmbiance]: 0.4,
  [ISongs.FeatureTrigger]: 0.2,
  [ISongs.ScatterAppear_1]: 0.2,
  [ISongs.ScatterAppear_2]: 0.2,
  [ISongs.ScatterAppear_3]: 0.2,
  [ISongs.ScatterWin]: 0.2,
  [ISongs.UltraWin]: 0.2,
  [ISongs.SuperWin]: 0.2,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
