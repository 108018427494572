import { calcPercentage, isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
} from '../config';
import { UiButton } from '../ui/uiButton';

class CancelBtn extends UiButton {
  constructor() {
    super('close');
    this.interactive = !this.isDisabled;
    this.name = 'ControlBtn';
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    const x = this.applicationSize.width - gap;
    const y = gap;
    this.setSize(size);

    this.x = x;
    this.y = y;
  }
}

export default CancelBtn;
